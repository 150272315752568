.nav-tabs {
  .nav-link {
    color: $gray-600;

    &:hover {
      cursor: pointer;
    }

    &.active {
      color: $gray-800;
      background: #fff;
      border-color: $border-color;
      border-bottom-color: #fff;

      &:focus {
        background: #fff;
        border-color: $border-color;
        border-bottom-color: #fff;
      }
    }
  }
}

.tab-content {
  margin-top: -1px;
  background: #fff;
  border: 1px solid $border-color;

  .tab-pane {
    padding: $spacer;
  }
}

.card-block {
  .tab-content {
    margin-top: 0;
    border: 0;
  }
}

.nav-fill {
  .nav-link {
    background-color: #fff;
    border-color: $border-color;
  }

  .nav-link + .nav-link {
    margin-left: -1px;
  }

  .nav-link.active {
    margin-top: -1px;
    // margin-left: 0;
    border-top: 2px solid $primary;
  }
}

// Right-to-Left Support
*[dir="rtl"] {
  .nav {
    padding-right: 0;
  }
}
