@import "spd-coreui-customization";
@import "coreui/coreui";

@media (max-width: 400px) {
  .logos {
    padding-bottom: 16px;
    min-height: 80px;

    img {
      max-width: 30%;
    }
  }
}

@media (min-width: 401px) {
  .logos {
    padding-bottom: 16px;
    min-height: 120px;

    img {
      max-height: 60px;
    }
  }
}

@media print {
  .no-print, .no-print * {
    display: none !important;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

div .input-group,
    .form-group,
    .form {
      small {
        text-align: right;
        min-height: 25px;
        width: 100%;
        opacity: 0;
        color: $text-muted;
        transition: opacity .3s linear;
        display: block;
        margin-top: 0.25rem;
      }
}

div .input-group:focus-within,
    .input-group:hover,
    .form-group:focus-within,
    .form-group:hover,
    .form:focus-within,
    .form:hover {
      small {
        opacity: 1;
        transition: opacity .3s linear;
      }
}

.line-break {
  white-space: pre-line;
}

.navbar {
  min-height: 68px;
}

.body-with-fixed-header {
  margin-top: 80px;
  margin-bottom: 60px;
}

.app-footer {
  background-color: $primary;

  img {
    padding-top: 5px;
    width: 90px;
    padding-bottom: 5px;
  }
}

.no-padding {
  padding: 0;
}

.register-form {
  .input-group-text {
    min-width: 150px;
  }
}

.lead-identification-form {
  .input-group-text {
    min-width: 190px;
  }
}

.internal-evaluation-radio-wrapper label {
  display: block;
}

.td-score-input {
  min-width: 100px;
  max-width: 100px;

  input {
    text-align: center;
  }
}

.p-card-adjust {
  padding: 0.4em !important;
  p {
    margin: 0.5em;
    padding: 0;
    font-size: 0.975em;
  }
}

.header-card-adjust {
  font-size: 14px;
  font-weight: 800;
  text-align: center;
  padding: 0.35rem 1.25rem;
}
