.input-group-prepend,
.input-group-append {
  white-space: nowrap;
  vertical-align: middle; // Match the inputs
}

//
// RTL Support
//
*[dir="rtl"] {

  .input-group {
    > .form-control,
    > .custom-select {
      @include border-radius($input-border-radius, 0);

      &:not(:last-child) {
        @include border-left-radius(0);
      }

      &:not(:first-child) {
        @include border-right-radius(0);
      }
    }
  }

  .input-group-prepend {
    margin-left: -$input-border-width;
  }

  .input-group-append {
    margin-right: -$input-border-width;
  }

  // Prepend and append rounded corners
  //
  // These rulesets must come after the sizing ones to properly override sm and lg
  // border-radius values when extending. They're more specific than we'd like
  // with the `.input-group >` part, but without it, we cannot override the sizing.

  .input-group > .input-group-prepend > .btn,
  .input-group > .input-group-prepend > .input-group-text,
  .input-group > .input-group-append:not(:last-child) > .btn,
  .input-group > .input-group-append:not(:last-child) > .input-group-text,
  .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
  .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
    @include border-radius($input-border-radius, 0);
    @include border-left-radius(0);
  }

  .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
  .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
    @include border-right-radius(0);
  }

  .input-group > .input-group-append > .btn,
  .input-group > .input-group-append > .input-group-text,
  .input-group > .input-group-prepend:not(:first-child) > .btn,
  .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
  .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
  .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
    @include border-radius($input-border-radius, 0);
    @include border-right-radius(0);
  }

  .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
  .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
    @include border-left-radius(0);
  }

}
